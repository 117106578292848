@font-face {
  font-family: "Urbanist";
  src: url("./assets/font/Urbanist-Regular.ttf");
}
@font-face {
  font-family: "Manrope";
  src: url("./assets/font/Manrope-Regular.ttf");
}

:root {
  --primary-color: rgba(165, 148, 245, 1);
  --primary-dark-color: rgba(165, 148, 245);
  --clr-bg-header: transparent;
  --clr-btn: rgba(165, 148, 245, 1);
  --clr-nav-hover: rgba(165, 148, 245, 1);
  --clr-light: #FAFAFA;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  /* overflow-x: hidden; */
  background-color: #F7FCFF;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Urbanist';
}
p,span,a{
  font-family: 'Manrope';
  color: #4B4B4B;
  font-style: normal;
  font-weight: 400;
}
.f-w-11{
  font-size: 11px;
}
ul {
  /* list-style: none; */
  margin-bottom: 0px;
  padding-left: 0px;
}
.text-yellow{
  color: rgba(165, 148, 245, 1);
}
.bg-light-wiser
{
  background: rgba(165, 148, 245, 0.11);

}
a {
  text-decoration: none;
}

header {
  position: sticky;
  top: 0px;
  background-color: var(--clr-bg-header);
  width: 100%;
  z-index: 1000;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #9b9898; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000; 
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(30, 130, 95, 0.5);
}
.brandlogo{
 width: 120px;
 height: 65px;
 
}
.brandlogo img{
  width: 100%;
  cursor: pointer;
 }
.fontSize48{
  font-size: 48px;
}
.logBtn {
  display: inline-block;
  padding: 1rem 1.3rem;
  font-size: .8rem;
  border-radius: 100px;
  line-height: 1;
  margin: 0 .2rem;
  transition: .3s;
  text-transform: uppercase;
  background: rgba(165, 148, 245, 0.14);
  color: #FFF;
  backdrop-filter: blur(18.799999237060547px);
}

.logBtn.transparent:hover {
  background-color: var(--primary-dark-color);
  color: var(--clr-light);
}

.logBtn.solid:hover {
  background-color: transparent;
  color: var(--clr-light);
}

.nav-links > ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-link {
  position: relative;
}

.nav-link > a {
  line-height: 3rem;
  /* color: var(--clr-light); */
  padding: 0 .6rem;
  letter-spacing: 1px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .5s;
}



.nav-link:hover > a {
  transform: scale(1.1);
  color: rgba(165, 148, 245, 1);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 10rem;
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
  transition: .5s;
}

.dropdown ul {
  position: relative;
}

.dropdown-link > a {
  display: flex;
  background-color: var(--clr-light);
  color: var(--clr-dropdown);
  padding: .5rem 1rem;
  font-size: .9rem;
  align-items: center;
  justify-content: space-between;
  transition: .3s;
}

.dropdown-link:hover > a {
  background-color: var(--clr-dropdown);
  color: var(--clr-light);
}

.dropdown-link:not(:nth-last-child(2)) {
  border-bottom: 1px solid var(--clr-light);
}

.dropdown-link i {
  transform: rotate(-90deg);
}

.arrow {
  position: absolute;
  width: 11px;
  height: 11px;
  top: -5.5px;
  left: 32px;
  background-color: var(--clr-light);
  transform: rotate(45deg);
  cursor: pointer;
  transition: .3s;
  z-index: -1;
}

.hamburger-menu-container {
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.hamburger-menu {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hamburger-menu div {
  width: 1.6rem;
  height: 3px;
  border-radius: 3px;
  background-color: var(--clr-light);
  position: relative;
  z-index: 1001;
  transition: .5s;
}

.hamburger-menu div:before,
.hamburger-menu div:after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: var(--clr-light);
  border-radius: 3px;
  transition: .5s;
}

.hamburger-menu div:before {
  transform: translateY(-7px);
}

.hamburger-menu div:after {
  transform: translateY(7px);
}

#check {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 90000;
  cursor: pointer;
  opacity: 0;
  display: none;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div {
  background-color: transparent;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:before {
  transform: translateY(0) rotate(-45deg);
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:after {
  transform: translateY(0) rotate(45deg);
}

@keyframes animation {
  from {
    opacity: 0;
    transform: translateY(15px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}



.contentNavbar {
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.13); ;
}
.video-bg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
}
.heroTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1; 
}
.lineBarText{
  font-size: 16px;
}
.reqDemoBtn{
  padding: 16px 30px;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 19px 26px -9px rgba(0, 0, 0, 0.17);
  color: #000;
}
.reqDemoBtn:hover{
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.active_link{
  color: var(--primary-dark-color) !important; 
}
.smLogbtn{
  display: none;
}
.yLogo{
  width: 100%;
  height: 100%;
}
.aboutUs{
  padding: 80px 0px;
  background-color: #F7FCFF;
}
.neurocognitive
{
  background: rgba(165, 148, 245, 0.11);
  padding: 100px 0px;
  position: relative;
  margin: 20px;
  border-radius: 10px;
}
.bgWiser{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 400px;
  height: 220px;
  /* background: red; */
}
.Bgwiser{
  width: 100%;
  height: 100%;
}
.neurocogTittle{
  margin-top: 15px;
  font-size: 21px;
  color: #282828;
}
.neurocogdetails
{
  font-size: 14px;
  color: #4B4B4B;
  margin-top: 10px;
}
.problemsFace{
  border-radius: 21px;
  background: rgba(165, 148, 245, 0.11);
  padding-top: 80px;
  margin: 20px;
}
.prblemIcons
{
  border-radius: 10px;
  background: #FFF;
  box-shadow: 9px 12px 30.4px 0px rgba(0, 0, 0, 0.11);
  padding: 10px;
}
.educationCard
{
  padding: 15px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.03), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01);
}
.brandLogos{
  width: 100%;
  height: 100%;
}
.clientLogo{
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
  transition: transform 0.3s ease-in-out;
}
.clientLogo:hover {
  transform: scale(1.1); 
  cursor: pointer;
}
.reqDemo{
  width: 100%;
  height: 400px;
  position: relative;
}
.reqDemoImg{
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.reqDemoDetail{
  position: absolute;
  top: 16%;
  left: 15%;
  z-index: 2;
}
.reqforDemoBtn
{ 
  padding: 16px 30px;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 19px 26px -9px rgba(0, 0, 0, 0.17);
  color: #000;
}
.reqforDemoBtn:hover{
  background: var(--primary-dark-color);
  color: #000;
  border: none;
}
.footerlogo{
  width: 200px;
  height: 110px;
  margin: auto;
}
.footerlogo img{
  width: 100%;
  height: 100%;
}
.footerSty{
  background-color: rgba(37, 25, 99, 1);
  ;
}
.social_link:hover{
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 100%;
}
.rightandresv{
  border-top: 0.95px solid rgba(255, 255, 255, 0.17)
}


.othersHeroSec{
  background-position: center;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.contact_main_div {
  background-image: url("./assets/contactBG.png");
}
.req_main_div{
  background-image: url("./assets/reqDemoBG.png");
}
.wiserad_main_div{
  background-image: url("./assets/wiserAdBG.png");
}
.career_main_div{
  background-image: url("./assets/careerBG.png");
}
.careerDet_main_div{
  background-image: url("./assets/careerDetailsBG.png");
}
.contentData{
  position: absolute;
  top:45%;
  left: 30%;
}
.contactUsPg{
  padding-bottom: 100px;
}
.contactFromBg{
  position: absolute;
  top: -7%;
  right: 0;
  z-index: -1;
  width: 250px;
  height: 200px;
}
.demoFromBg{
  position: absolute;
  top: 20%;
  left: -10%;
  width: 600px;
  height: 400px;
}
.demoFromBg img{
  width: 100%;
  height: 100%;
}
.contactFromBg img{
  width: 100%;
  height: 100%;
} 
.formDataReq{
  border-radius: 15.937px;
  background: #FFF;
  box-shadow: 0px 14.8px 48.758px 0px rgba(0, 0, 0, 0.10);
  padding: 20px;
  margin-top: -10%;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.form-control{
  border-radius: unset;
  border: none;
  border-bottom: 1px solid black;
  padding: .375rem .75rem;
}
.form-control:focus {
  color: none;
  background-color: var(--bs-body-bg);
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.form-control:focus{
  border-color: #000;
}
.form-group{
  margin: 30px 5px;
}
.form-check{

}
.submitQueryBtn{
  margin-top: 20px;
  border-radius: 30px;
  padding: 16px 30px;
  border: 1px solid #000;
  background: #FFF;
  box-shadow: 0px 19px 26px -9px rgba(0, 0, 0, 0.17);
}
.submitQueryBtn:hover{ 
  background-color: var(--primary-dark-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}





.activeBtn.careerDetailActiveBtn {
  width: 35px;
  height: 35px;
}
.inactiveBtn.careerDetailActiveBtn {
  width: 35px;
  height: 35px;
}
.inactiveBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  color: #2B2B2B;
  background-color: #fff;
}
.activeBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: transparent;
  background-color: rgba(37, 25, 99, 1);
}
.first_slide_img{
  position: absolute;
  right: 0;
  top:0;
}
.wiseradviserSec{
  background: rgba(244, 241, 244, 1);
  padding: 100px 0px;
  margin: 80px 0px;
}
.wiserTitle{
  color: rgba(37, 25, 99, 1);
  margin: 5px 0px;
}
.featureSection{
  margin: 100px 0px;
  position: relative;
}
.feature{
  position: relative;
}
.w-sm-resp{
  width: 60%;
}
.WiserbeeBGMain{
  position: absolute;
  top: 30%;
  right: 0;
  width: 50%;
  height: 50%;
}
.WiserbeeBGMain img{
  width: 100%;
  height: 100%;
}
.virtualSecion{
  margin: 100px 0px;
}

.cardStyle{
  height: 350px;
  width: 300px;
  border-radius: 10px;
}

.cardIner{
  position: relative;
  width: 100%;
  height: 150px;
  padding-bottom: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.cardStyle:hover .cardIner{
  transform: rotateY(180deg);
}

.cardFront, .cardBack{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}


.cardBack{
  background: #251963;
  color: #fff;
  transform: rotateY(180deg);
}
.cardBack div{
  padding: 15px;
}
.profileDescription{
  font-size: 12px;
}
.myLink{
  color: #fff;
}



























@media (max-width: 575.98px){
  .cardStyle{
    height: auto;
  }
}

@media (max-width: 920px) {
  .active_link{
    background-color: #000 !important;
    color: #fff !important;
  }
  .hamburger-menu-container {
    display: flex;
  }

  #check {
    display: block;
  }
 
  .nav-btn {
    position: fixed;
    height: calc(100vh - 3rem);
    top: 6rem;
    left: 0;
    width: 100%;
    background-color: var(--clr-btn);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: .65s;
  }

  #check:checked ~ .nav-btn {
    transform: translateX(0);
  }

  #check:checked ~ .nav-btn .nav-link,
  #check:checked ~ .nav-btn .log-sign {
    animation: animation .5s ease forwards var(--i);
  }

  .nav-links {
    flex: initial;
    width: 100%;
  }

  .nav-links > ul {
    flex-direction: column;
  }

  .nav-link {
    width: 100%;
    opacity: 0;
    transform: translateY(15px);
  }

  .nav-link > a {
    line-height: 1;
    padding: 1.6rem 2rem;
  }

  .nav-link:hover > a {
    transform: scale(1);
    background-color: var(--clr-nav-hover);
  }


  .nav-link > a > i {
    font-size: 1.1rem;
    transform: rotate(-90deg);
    transition: .7s;
  }

  .log-sign {
    flex: initial;
    width: 100%;
    padding: 1.5rem 1.9rem;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(15px);
    display: none;
  }
  .smLogbtn{
    display: block !important;
  }
}
@media (max-width: 575.98px){
  .lineBarText{
    font-size: 10px;
  }
  .heroTitle{
    width: 100%;
  }
  .reqDemoDetail{
    left: 5%;
  }
  .brandlogo{
    width: 100px;
    height: 50px;
  }
  .nav-btn{
    top: 5rem;
  }
  .othersHeroSec{
    height: 300px;
  }
  .contentData{
    left: 5%;
  }
  .w-sm-resp{
    width: 90% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px){
.nav-link > a {
    font-size: 12px;
  }
}



.header
{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff;
}
.header .navigation
{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    position: relative;
}
.header .navigation li a
{
    text-decoration: none;
    color: var(--primary);
    padding: 5px 40px;
    transition: .3s ease-in-out;
    border-bottom: 2px solid #fff;
    left: 0;
}
.header .navigation li a:hover
{
    border-bottom: 2px solid var(--secondary);
}


section
  {
    padding: 40px 30px;
  }
.news
{
    text-align: center;
}
.news .titletext
{
    text-align: center;
    font-size: 2em;
}
.news .titletext span
{
    color: var(--secondary);
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2rem;
    gap: 2rem;
    background: var(--bg);
    border-radius: 20px;
  }
  
  img {
    max-width: 100%;
    object-fit: cover;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #fff;
  }
  
  .card__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  
  .tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    font-size: .75rem;
    background: var(--secondary);
    color: #fff;
  }
  
  .tag + .tag {
    margin-left: .5em;
  }
  
  .card__body h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  
  .card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
  }
  
@media(max-width: 911px){
    .header
    {
        display: none;
    }
    .banner
    {
    margin: 0px 0px;   
    }
}
